var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-1"},[_c('div',{staticClass:"card p-1 mb-0"},[_c('div',{staticClass:"mx-1 m-1 position-relative"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start justify-content-sm-start",attrs:{"cols":"12","md":"12","lg":"4"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-muted"},[_vm._v(" Mostrando "+_vm._s(_vm.start_page)+" a "+_vm._s(_vm.to_page)+" de "+_vm._s(_vm.total_data)+" registros ")]),_c('div',{staticClass:"d-flex align-items-center mt-1"},[_c('button',{staticClass:"btn btn-primary mr-1",on:{"click":_vm.changeStatusSpecimens}},[_vm._v("Actualizar estados")]),_c('button',{staticClass:"btn btn-success",on:{"click":_vm.specimensFound}},[_vm._v("Marcar como encontrados")])])])]),_c('b-col',{staticClass:"flex-nowrap",attrs:{"cols":"12","md":"12","lg":"8"}},[_c('div',{staticClass:"flex flex-column align-items-end"},[_c('div',{staticClass:"xt-search-input__paginate mt-1 mt-md-0"},[_c('b-pagination',{attrs:{"value":_vm.page,"total-rows":_vm.total_data,"per-page":_vm.perpage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (e) { return _vm.SET_FILTERS({
											search: _vm.search,
											page: e,
										}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}])})],1),_c('div',{staticClass:"xt-search-input__search w-100"},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-end justify-content-md-end"},[_c('b-form-input',{staticClass:"search-input",staticStyle:{"width":"200px"},attrs:{"type":"search","placeholder":"Placa o Alias"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.init($event)},"input":function($event){return _vm.SET_FILTERS({
											search: _vm.searchInput,
											page: _vm.page,
										})}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}),_c('div',{staticClass:"search-btn ml-0 ml-md-1 mt-1 mt-md-0"},[_c('button',{staticClass:"btn btn-primary w-100",on:{"click":_vm.init}},[_vm._v("Buscar")])])],1)])])])],1)],1)]),_c('ValidationObserver',{ref:"form",attrs:{"tag":"div"}},[_c('MainStates',{attrs:{"in_galpon":null,"isInventory":""}})],1),_c('b-modal',{attrs:{"hide-footer":"","title":_vm.modalFound.title},on:{"hide":_vm.refreshModalChilds},model:{value:(_vm.modalFound.show),callback:function ($$v) {_vm.$set(_vm.modalFound, "show", $$v)},expression:"modalFound.show"}},[_c('validation-observer',{ref:"formValid"},[_c('ValidationProvider',{staticClass:"w-100",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"options":_vm.childsInventories,"clearable":false,"label":"alias","reduce":function (option) { return option.id; },"transition":"","placeholder":"Buscar bloque de inventario"},model:{value:(_vm.child_inventory_id),callback:function ($$v) {_vm.child_inventory_id=$$v},expression:"child_inventory_id"}})]}}])})],1),_c('div',{staticClass:"containe-plates"},_vm._l((_vm.modalFound.data),function(plate,index){return _c('p',{staticClass:"plate"},[_vm._v(_vm._s(plate))])}),0),_c('div',{staticClass:"d-flex justify-content-end mt-1"},[_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.moveSpecimensToInventory}},[_vm._v("Agregar")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }