<template>
	<div>
		<div class="card container-table p-2 mb-0">
			<div class="table card-table-inventory">
				<div class="header-table-fixed">
					<div></div>
					<div class="date-text">
						<span>FECHAS</span>
					</div>
				</div>
				<div class="header-table">
					<div
						v-for="(title, key) in headTable"
						:key="key"
						class="d-flex align-items-center justify-content-center cursor-pointer container-title"
						v-if="title.visible"
					>
						<!-- code -->

						<p class="mb-0 font-weight-bolder text-uppercase title">{{ title.title }}</p>
					</div>
				</div>

				<template v-if="inventories.length > 0">
					<div class="body-table" v-for="(item, index) in inventories" :key="index">
						<div class="grid-table text-center">
							<div
								@click="
									item.children &&
										item.children.length > 0 &&
										ACTIVE_CHILDREN_INVENTORIES({ index, key: !item.active })
								"
								class="d-flex align-items-center justify-content-center"
							>
								<FeatherIcon
									icon="ChevronDownIcon"
									size="20"
									class="icon mr-25"
									:class="[
										item.active ? 'active-icon' : '',
										item.children && item.children.length > 0
											? 'text-primary cursor-pointer'
											: 'text-secondary',
									]"
								/>
								<p
									class="mb-0 font-weight-bolder"
									:class="[
										item.children && item.children.length > 0
											? 'text-primary cursor-pointer'
											: 'text-secondary',
									]"
								>
									{{ item.code }}
								</p>
							</div>

							<div class="cell">
								<p class="mb-0" v-if="item.start_date">{{ item.start_date | myGlobalDayShort }}</p>
								<p class="mb-0" v-else>---</p>
								<FeatherIcon
									v-if="[1, 2].includes(item.status_id)"
									@click="
										modalUpdateDate.show = true;
										modalUpdateDate.title = `Actualizar fecha / ${item.code}`;
										modalUpdateDate.inventory_id = item.id;
										modalUpdateDate.value = item.start_date;
									"
									icon="Edit3Icon"
									size="18"
									class="icon text-dark ml-25 cursor-pointer"
								/>
							</div>

							<div class="cell">
								<p class="mb-0" v-if="item.end_date">{{ item.end_date | myGlobalDayShort }}</p>
								<p class="mb-0" v-else>---</p>
							</div>

							<div class="cell">
								<p
									:style="{}"
									class="mb-0 font-weight-bolder"
									:class="[item.status_id != 3 ? 'text-primary ' : 'text-secondary']"
								>
									{{ item.value.length }}
								</p>
							</div>

							<div class="cell">
								<p
									class="mb-0 font-weight-bolder"
									:class="[item.status_id != 3 ? 'text-success ' : 'text-secondary']"
								>
									{{ item.counters.match }}
								</p>
							</div>

							<div class="cell">
								<p
									class="mb-0 font-weight-bolder"
									:class="[
										[1, 2].includes(item.status_id) && item.counters.missing > 0
											? 'text-danger '
											: 'text-secondary',
									]"
								>
									{{ item.counters.missing }}
								</p>
							</div>

							<div class="cell">
								<!-- {{ item.status_id }} -->
								<p
									v-if="
										item.children &&
										item.children.length > 0 &&
										item.status_id != 1 &&
										sumMissing(item.children ? item.children : []) == 0
									"
									class="mb-0 font-weight-bolder"
									:class="[
										[1, 2].includes(item.status_id) && item.counters.spare > 0
											? 'text-danger cursor-pointer'
											: 'text-secondary',
									]"
									@click="setSidebarRemoveSpareItems(item)"
								>
									{{ item.counters.spare }}
								</p>
								<p v-else class="mb-0">---</p>
							</div>

							<div class="cell flex-column">
								<p class="mb-0 font-weight-bolder text-warning">
									{{ item.children && item.children.length }}
								</p>
							</div>

							<div class="cell flex-column">
								<p class="mb-0 font-weight-bolder font-small-3">
									{{ item.status.toUpperCase() }}
								</p>
							</div>

							<div class="cell flex-column">
								<p class="mb-0">{{ item.name }}</p>
								<span class="font-small-3">{{ item.created_at | myGlobalDayShort }}</span>
							</div>

							<div class="cell">
								<FeatherIcon
									@click="[1, 2].includes(item.status_id) && crearBloqueInventory(item)"
									v-if="item.level == 1"
									icon="PlusIcon"
									size="20"
									class="cursor-pointer icon mr-1"
									:class="[[1, 2].includes(item.status_id) ? 'text-primary' : 'text-secondary']"
									v-b-tooltip.hover
									:title="[1, 2].includes(item.status_id) ? 'Agregar bloque' : ''"
								/>

								<FeatherIcon
									v-if="item.status_id == 1"
									@click="updateToggleChild(item)"
									icon="FastForwardIcon"
									size="20"
									class="cursor-pointer icon"
									:class="[[1, 2].includes(item.status_id) ? 'text-primary' : 'text-secondary']"
									v-b-tooltip.hover
									:title="[1, 2].includes(item.status_id) ? 'Procesar' : ''"
								/>
								<template v-else>
									<FeatherIcon
										v-if="item.status_id != 3"
										@click="finishedInventory(item)"
										icon="CheckSquareIcon"
										size="20"
										class="icon cursor-pointer"
										:class="[
											!(
												item.counters.match == item.value.length &&
												item.counters.missing == 0 &&
												item.counters.spare == 0
											)
												? 'text-secondary'
												: 'text-primary',
										]"
										v-b-tooltip.hover
										:title="
											!(
												item.counters.match == item.value.length &&
												item.counters.missing == 0 &&
												item.counters.spare == 0
											)
												? 'No es posible finalizar'
												: 'Finalizar inventario'
										"
									/>
									<FeatherIcon
										v-else
										v-b-tooltip.hover
										title="Finalizado"
										icon="CheckSquareIcon"
										size="20"
										class="icon text-success"
									/>
								</template>

								<!-- :disabled="
										!(
											item.counters.un_match == 0 &&
											item.counters.missing == 0 &&
											item.counters.spare == 0
										)
									" -->
							</div>
						</div>
						<!-- :class="item.active ? 'active-container-chidren' : ''" -->
						<div
							class="container-chidren"
							:style="{
								height: item.active
									? `${hgChildren(item.children ? item.children.length : [])}px`
									: '0',
							}"
						>
							<!-- :class="[item.active ? 'active-accordeon' : '']" -->

							<!-- <h2>Contenido</h2> -->
							<!-- {{ item.children }} -->
							<!-- :style="{ minHeight: '70vh' }" -->
							<div class="table-children">
								<div>
									<div class="header-feature">
										<p></p>
										<p class="date-text">Fechas</p>
									</div>
									<div class="header">
										<p>Bloque</p>
										<p>Inicio</p>
										<p>Fin</p>
										<p>Comentario</p>
										<p>Ingresados</p>
										<p>Encontrados</p>
										<p>No encontrados</p>
									</div>

									<div class="container-body">
										<div class="body" v-for="(itemChildren, key) in item.children" :key="key">
											<div class="cell">
												<p class="mb-0 font-weight-bolder">{{ itemChildren.alias }}</p>
												<!-- {{ item.status_id }} -->
											</div>
											<div class="cell d-flex align-items-center justify-content-center">
												<p v-if="itemChildren.start_date" class="mb-0">
													{{ itemChildren.start_date | myGlobalDayShort }}
												</p>
												<p v-else class="mb-0">---</p>

												<FeatherIcon
													v-if="!itemChildren.end_date"
													@click="
														modalUpdateDate.show = true;
														modalUpdateDate.title = `Actualizar fecha / ${itemChildren.alias} / ${itemChildren.dad_code}`;
														modalUpdateDate.inventory_id = itemChildren.id;
														modalUpdateDate.value = itemChildren.start_date;
													"
													icon="Edit3Icon"
													size="18"
													class="icon text-dark ml-25 cursor-pointer"
												/>
											</div>
											<div class="cell">
												<p v-if="itemChildren.end_date" class="mb-0">
													{{ itemChildren.end_date | myGlobalDayShort }}
												</p>
												<p v-else class="mb-0">---</p>
											</div>
											<div class="cell">
												<div class="d-flex align-itemChildrens-center justify-content-center">
													<p
														v-if="itemChildren.comment"
														class="mb-0"
														v-b-tooltip.hover
														:title="itemChildren.comment"
													>
														{{
															itemChildren.comment && itemChildren.comment.length > 50
																? `${itemChildren.comment.slice(0, 50)}...`
																: itemChildren.comment
														}}
													</p>
													<p v-else class="mb-0">---</p>

													<FeatherIcon
														@click="
															modalUpdateComment.show = true;
															modalUpdateComment.title = `Actualizar comentario / ${itemChildren.alias}`;
															modalUpdateComment.inventory_id = itemChildren.id;
															modalUpdateComment.value = itemChildren.comment;
															modalUpdateComment.ids = { parent: index, child: key };
														"
														icon="Edit3Icon"
														size="18"
														class="icon text-dark ml-1 cursor-pointer"
													/>
												</div>
											</div>
											<div class="cell">
												<p
													class="mb-0 font-weight-bolder"
													@click="
														itemChildren.status_id != 3 &&
															addPlates(itemChildren, item.code)
													"
													:class="[
														itemChildren.status_id != 3
															? 'text-primary cursor-pointer'
															: 'text-secondary',
													]"
												>
													{{ itemChildren.value.length }}
												</p>
											</div>
											<div class="cell">
												<p
													class="mb-0 font-weight-bolder"
													:class="[
														itemChildren.status_id != 3
															? 'text-success cursor-pointer'
															: 'text-secondary',
													]"
													@click="
														itemChildren.status_id != 3 &&
															itemChildren.match != 0 &&
															setActionInventory(itemChildren, 0)
													"
												>
													{{ itemChildren.counters.match }}
												</p>
											</div>
											<div class="cell">
												<p
													class="mb-0 font-weight-bolder"
													:class="[
														itemChildren.status_id != 3
															? 'text-danger cursor-pointer'
															: 'text-secondary',
													]"
													@click="
														itemChildren.status_id != 3 &&
															itemChildren.match != 0 &&
															setActionInventory(itemChildren, 1)
													"
												>
													{{ itemChildren.counters.missing }}
												</p>
											</div>
										</div>
										<div v-if="item.children && item.children.length == 0">
											<div colspan="7" class="text-center">
												<p class="mb-0 font-weight-bold">Sin bloques encontrados</p>
											</div>
										</div>
									</div>
								</div>

								<!-- <b-table-simple sticky-header :style="{ minHeight: '5vh' }">
									<b-thead>
										<b-tr class="bg-transparent">
											<b-th colspan="1" class="bg-transparent border-0" />
											<b-th
												colspan="2"
												class="border-white bg-info text-white text-center box-primary p-25"
												>FECHAS</b-th
											>
										</b-tr>

										<b-tr>
											<b-th>
												<div
													class="d-flex align-items-center justify-content-center cursor-pointer"
												>
													<p class="mb-0">Bloque</p>
												</div>
											</b-th>
											<b-th>
												<div
													class="d-flex align-items-center justify-content-center cursor-pointer"
												>
													<p class="mb-0">Inicio</p>
												</div>
											</b-th>
											<b-th>
												<div
													class="d-flex align-items-center justify-content-center cursor-pointer"
												>
													<p class="mb-0">Fin</p>
												</div>
											</b-th>
											<b-th>
												<div
													class="d-flex align-items-center justify-content-center cursor-pointer"
												>
													<p class="mb-0">Comentario</p>
												</div>
											</b-th>
											<b-th>
												<div
													class="d-flex align-items-center justify-content-center cursor-pointer"
												>
													<p class="mb-0">Ingresados</p>
												</div>
											</b-th>
											<b-th>
												<div
													class="d-flex align-items-center justify-content-center cursor-pointer"
												>
													<p class="mb-0">Encontrados</p>
												</div>
											</b-th>
											<b-th>
												<div
													class="d-flex align-items-center justify-content-center cursor-pointer"
												>
													<p class="mb-0">No encontrados</p>
												</div>
											</b-th>
										</b-tr>
									</b-thead>
									<b-tbody> </b-tbody>
								</b-table-simple> -->
							</div>
						</div>
					</div>
				</template>

				<div v-else>
					<div colspan="10" class="text-center mt-1 mb-1">
						<p class="mb-0 font-weight-bold">Sin inventarios encontrados</p>
					</div>
				</div>
			</div>
		</div>

		<b-sidebar
			v-model="sidebarCrearInventario.show"
			:title="sidebarCrearInventario.title"
			left
			width="90%"
			sidebar-class="sidebar-class"
			body-class="p-0 bg-white"
			scrollable
			@hidden="hideModal"
		>
			<!-- <h2>AA</h2> -->
			<CreateInventorySpecimens
				@refresh="refresh"
				v-if="sidebarCrearInventario.show"
				:data="dataSpecimens"
				:module="module"
				:inventory_id="sidebarCrearInventario.inventory_id"
				:aliasAux="sidebarCrearInventario.alias"
				:commentAux="sidebarCrearInventario.comment"
			/>
		</b-sidebar>

		<b-sidebar
			v-model="sidebarRemoveSpare.show"
			:title="sidebarRemoveSpare.title"
			left
			width="90%"
			sidebar-class="sidebar-class"
			body-class="p-0 bg-white"
			scrollable
			@hidden="hideModal"
		>
			<RemoveSpecimensSpareToInventory
				@refresh="refresh"
				v-if="sidebarRemoveSpare.show"
				:module="module"
				:inventory_id="sidebarRemoveSpare.inventory_id"
			/>
		</b-sidebar>

		<b-modal hide-footer v-model="modalUpdateComment.show" :title="modalUpdateComment.title">
			<!-- <h2>Hola mundo</h2> -->
			<!-- {{ modalUpdateComment.inventory_id }} -->

			<b-form-group label="Comentario">
				<b-form-textarea
					v-model="modalUpdateComment.value"
					placeholder="Ingresar comentario"
					rows="3"
					max-rows="6"
				></b-form-textarea>
			</b-form-group>

			<div class="d-flex align-items-center justify-content-end">
				<button @click="updateComment" class="btn btn-primary">Guardar</button>
			</div>
		</b-modal>

		<b-modal >
			<b-form-group label="Fecha de inicio">
				<flat-pickr
 					class="form-control bg-white"
					placeholder="Seleccionar fechas"
					dateFormat="m-Y-d"
				/>
			</b-form-group>

			<div class="d-flex align-items-center justify-content-end">
				<button @click="updateDate" class="btn btn-primary">Guardar</button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import inventoryService from "@/services/inventory.service";
import { mapState, mapActions, mapMutations } from "vuex";
import CreateInventorySpecimens from "../components/CreateInventorySpecimens.vue";
// import fakeSpecimens from "../fake_specimens.js";
import RemoveSpecimensSpareToInventory from "../components/RemoveSpecimensSpareToInventory.vue";
import moment from "moment";

export default {
	name: "ViewInventorySpecimens",
	components: {
		CreateInventorySpecimens,
		RemoveSpecimensSpareToInventory,
	},
	data() {
		return {
			headTable: [
				{ key: "code", title: "Codigo", visible: true, grid: 2 },
				{ key: "fecha1", title: "Inicio", visible: true, grid: 2 },
				{ key: "fecha2", title: "Fin", visible: true, grid: 2 },
				{ key: "plates", title: "Ingresados", visible: true, grid: 2 },
				{ key: "match", title: "Encontrados", visible: true, grid: 2 },
				// { key: "unmatch", title: "#No coinciden", visible: true },
				{ key: "missing", title: "No encontrados", visible: true, grid: 2 },
				{ key: "leftOver", title: "Restantes", visible: true, grid: 2 },

				{ key: "bloques", title: "Bloques", visible: true, grid: 2 },
				{ key: "status", title: "Estado", visible: true, grid: 2 },
				{ key: "usuario", title: "Creado por", visible: true, grid: 2 },
				{ key: "actions", title: "Acciones", visible: true, grid: 2 },
			],
			sidebarCrearInventario: {
				show: false,
				title: null,
				data: [],
				inventory_id: null,
				alias: null,
				comment: null,
				// code: null
			},
			dataSpecimens: [],
			sidebarRemoveSpare: {
				show: false,
				title: null,
				data: [],
				inventory_id: null,
			},
			modalUpdateComment: {
				show: false,
				title: null,
				inventory_id: null,
				value: null,
			},
			modalUpdateDate: {
				show: false,
				title: null,
				inventory_id: null,
				value: null,
			},
			comment: null,
		};
	},
	computed: {
		...mapState("inventorySpecimens", ["inventories", "inventory"]),
		module() {
			return this.$route.meta.module;
		},
		hgChildren: () => (num) => {
			return 42.66 + (num > 6 ? 6 : num) * 42.66 + 64 + 0;
		},
		sumMissing: () => (items) => {
			return items.reduce(function (previousValue, currentValue) {
				return previousValue + currentValue.counters.missing;
			}, 0);
		},
	},
	mounted() {
		// this.isPreloading();
		this.init();
	},
	methods: {
		...mapActions("inventorySpecimens", ["getInventories"]),
		...mapMutations("inventorySpecimens", [
			"SELECT_INVENTORY",
			"UPDATE_INDEX_INVENTORY",
			"ACTIVE_CHILDREN_INVENTORIES",
			"UPDATE_COMMENT",
		]),
		async init() {
			this.isPreloading();
			// this.isPreloadingBg();
			await this.getInventories({ module: this.module });
			// this.isPreloading(false);
		},
		createInventory() {
			this.sidebarCrearInventario.show = true;
			this.sidebarCrearInventario.title = `Nuevo inventario - ${this.module == 1 ? "Ejemplares" : "Pollos"}`;
			this.SELECT_INVENTORY({
				item: {
					value: [],
				},
			});
		},
		setInventoryParcial(item) {
			const name = `inventory-specimens-by-id-parciales-sr`;
			this.SELECT_INVENTORY({ item });

			this.$router.push({
				name,
				params: {
					inventory_id: item.id,
				},
			});
		},
		setActionInventory(item, key) {
			const name = this.module == 1 ? `inventory-specimens-by-id-sr` : `inventory-chicks-by-id-sr`;
			this.SELECT_INVENTORY({ item });

			this.UPDATE_INDEX_INVENTORY({ index: key });

			this.$router.push({
				name,
				params: {
					inventory_id: item.id,
				},
				query: {
					match: key,
				},
			});
		},
		addPlates(item, codeParent) {
			this.SELECT_INVENTORY({ item });
			this.sidebarCrearInventario.show = true;
			this.sidebarCrearInventario.title = `${this.inventory.code || this.inventory.alias} ${
				codeParent ? ` / ${codeParent}` : null
			}`;
			this.sidebarCrearInventario.alias = item.alias;
			this.sidebarCrearInventario.comment = item.comment;
			// this.sidebarCrearInventario.code = codeParent;
		},
		async processInventory(inv) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const { message } = await inventoryService.update({
				id: inv.id,
				status_id: 2,
			});
			this.isPreloading(false);
			this.showToast("success", "top-right", "Inventario", "SuccessIcon", message);
			await this.init();
		},
		async finishedInventory(inv) {
			console.log("inv", inv);
			if (!(inv.counters.match == inv.value.length && inv.counters.missing == 0 && inv.counters.spare == 0)) {
				return this.showToast(
					"warning",
					"top-right",
					"Inventario",
					"WarningIcon",
					"No es posible finalizar este inventario."
				);
			}

			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const { message } = await inventoryService.update({
				id: inv.id,
				status_id: 3,
				module: this.module,
				end_date: moment(new Date()).format("YYYY-MM-DD"),
			});
			this.isPreloading(false);
			this.showToast("success", "top-right", "Inventario", "SuccessIcon", message);
			await this.init();
		},
		async refresh() {
			console.log("refresh");
			this.sidebarCrearInventario.show = false;
			await this.init();
		},
		hideModal() {
			this.SELECT_INVENTORY({
				item: {
					value: [],
				},
			});
			this.refreshAliasComment();
		},
		changeVisibleAccordeon(item) {
			console.log("item", item);
		},
		crearBloqueInventory(item) {
			this.sidebarCrearInventario.inventory_id = item.id;
			// console.log("item", item);
			this.createInventory();
			this.sidebarCrearInventario.title = `Nuevo bloque / ${item.code}`;
		},
		setSidebarRemoveSpareItems(item) {
			this.sidebarRemoveSpare.show = true;
			this.sidebarRemoveSpare.title = `Restantes / ${item.code}`;
			this.sidebarRemoveSpare.inventory_id = item.id;
		},
		async updateComment() {
			this.isPreloading();
			const { status, message } = await inventoryService.updateComment({
				comment: this.modalUpdateComment.value,
				id: this.modalUpdateComment.inventory_id,
			});
			if (status) {
				const { parent, child } = this.modalUpdateComment.ids;
				this.UPDATE_COMMENT({ index: parent, key: child, comment: this.modalUpdateComment.value });
				this.showToast("success", "top-right", "Inventarios", "SuccessIcon", message);

				this.modalUpdateComment.show = false;
			}
			this.isPreloading(false);
		},
		async updateDate() {
			this.isPreloading();
			const { status, message } = await inventoryService.update({
				start_date: moment(this.modalUpdateDate.value).format("YYYY-MM-DD"),
				id: this.modalUpdateDate.inventory_id,
			});
			if (status) {
				// const { parent, child } = this.modalUpdateDate.ids;
				// this.UPDATE_COMMENT({ index: parent, key: child, comment: this.modalUpdateDate.value });
				this.showToast("success", "top-right", "Inventarios", "SuccessIcon", message);
				await this.init();

				this.modalUpdateDate.show = false;
			}
			this.isPreloading(false);
		},
		async updateToggleChild(inv) {
			console.log(inv);
			if (inv.counters.match != inv.value.length || inv.children.length == 0) {
				return this.showToast(
					"warning",
					"top-right",
					"Inventario",
					"WarningIcon",
					"No es posible procesar el inventario."
				);
			}

			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;

			// console.log("inv", inv);
			const { message, status } = await inventoryService.update({
				id: inv.id,
				status_id: 2,
				module: this.module,
			});

			if (status) {
				// const { parent, child } = this.modalUpdateComment.ids;
				// this.UPDATE_COMMENT({ index: parent, key: child, comment: this.modalUpdateComment.value });
				this.showToast("success", "top-right", "Inventarios", "SuccessIcon", message);

				await this.init();

				// this.modalUpdateComment.show = false;
			}
		},
		refreshAliasComment() {
			this.sidebarCrearInventario.alias = null;
			this.sidebarCrearInventario.comment = null;
			this.sidebarCrearInventario.code = null;
		},
	},
};
</script>

<style lang="scss" scoped>
.container-table {
	overflow: auto;
}
.table {
	min-width: 1200px;
	// overflow: scroll;
	.header-table-fixed {
		display: grid;
		grid-template-columns: 1.8fr 1.2fr 1.2fr 2fr 2fr 2.3fr 2fr 1.2fr 1.5fr 2fr 1.2fr;
		.date-text {
			grid-column-start: 0;
			grid-column-end: span 2;
			padding: 0.2rem 0.5rem;
			// border-top-left-radius: 8px;
			// border-top-right-radius: 8px;
			text-align: center;
			background: #00bdd0;
			height: 29.5px;
			display: flex;
			align-items: center;
			justify-content: center;
			span {
				font-weight: bolder;
				color: #fff;
				font-size: 11.998px;
			}
		}
	}
	.header-table {
		border-left: 1px solid #f5f5f5 !important;
		border-right: 1px solid #f5f5f5 !important;
		display: grid;
		grid-template-columns: 1.8fr 1.2fr 1.2fr 2fr 2fr 2.3fr 2fr 1.2fr 1.5fr 2fr 1.2fr;
		background: #f3f2f7;
		padding: 0.5rem 0;
		@media screen and (min-width: 1400px) {
			padding: 1rem 0;
		}
		.container-title {
			text-align: center;
			.title {
				text-align: center;
				font-size: 12px;
				@media screen and (min-width: 1400px) {
					font-size: 14px;
				}
			}
		}
	}
	.body-table {
		border-left: 1px solid #f5f5f5 !important;
		border-right: 1px solid #f5f5f5 !important;
		border-bottom: 1px solid #f5f5f5 !important;
		// border-bottom: 8px;
		border-radius: 8px;
		.container-chidren {
			background: #f5f5f5 !important;
			border-radius: 8px;
			height: 0;
			min-height: 0;
			// max-height: 0;
			overflow: hidden;
			transition: 0.5s all ease-in-out;
			// padding: 2rem;
			// max-height: 300px;
			display: flex;
			align-items: center;
			justify-content: center;
			.table-children {
				width: 90%;
				// margin: 0 2rem;
				// padding: 2rem;
				// background: #fff;
				border-radius: 8px;
				padding: 2rem;
				// 0 4px 24px 0 rgb(34 41 47 / 10%) !important

				@media screen and (min-width: 1400px) {
					width: 80%;
				}

				.header-feature {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr 3fr 1.5fr 1.5fr 1.5fr;
					p {
						margin-bottom: 0;
						text-align: center;
					}
					.date-text {
						background: #00c3d1;
						padding: 0.2rem 0.5rem;
						color: #fff;
						font-weight: bolder;
						grid-column-start: 0;
						grid-column-end: span 2;
					}
				}
				.header {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr 3fr 1.5fr 1.5fr 1.5fr;
					background: #f4f1f7;
					border-top-left-radius: 8px;
					border-top-right-radius: 8px;
					box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
					p {
						margin-bottom: 0;
						padding: 0.8rem 0.5rem;
						text-align: center;
						font-weight: bolder;
					}
				}
				.container-body {
					background: #fff;
					max-height: 240px;
					overflow-y: auto;
					border-bottom-left-radius: 8px;
					border-bottom-right-radius: 8px;
					box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
					.body {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr 3fr 1.5fr 1.5fr 1.5fr;
						.cell {
							text-align: center;
							padding: 0.8rem 0.5rem;
						}
						border-bottom: 1px solid #f5f5f5;
						&:last-child {
							border-bottom: none;
						}
					}
				}
				.container-body::-webkit-scrollbar {
					width: 4px !important;
					background: #d5d1fb !important;
				}

				.container-body::-webkit-scrollbar-thumb {
					background: #7367f0 !important;
					border-radius: 6px !important;
					height: 4px;
				}
			}
		}
		.active-accordeon {
			height: 400px !important;
			overflow: auto;
			padding: 2rem;
			overflow: hidden;
		}
	}
	.grid-table {
		display: grid;
		grid-template-columns: 1.8fr 1.2fr 1.2fr 2fr 2fr 2.3fr 2fr 1.2fr 1.5fr 2fr 1.2fr;
		// padding: 0.5rem 0.5rem;

		.cell {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0.5rem 0;
		}

		.icon {
			transition: 0.4s all ease-in-out;
		}
		.active-icon {
			transform: rotate(180deg);
		}
	}
}
[dir] .b-table-sticky-header,
[dir] .table-responsive,
[dir] [class*="table-responsive-"] {
	margin-bottom: 0rem !important;
}
.card-table-inventory {
	// border: 1px solid #f5f5f5 !important;
	// background: red;
	border-radius: 8px;
}
.b-table-sticky-header {
	// background: red;
}
</style>
